/* eslint-disable max-lines */
/** @format */

import {
  ChatBubbleOvalLeftEllipsisIcon,
  ChevronRightIcon,
  LinkIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import {
  ENUM_CARD_SHARING,
  ENUM_CLASSIFICATION,
  ENUM_CUSTOMER_TYPE,
  ENUM_PLATFORM_TYPE,
  ENUM_TELEMATICS_SECURITY,
  ENUM_USERS_READ_WRITE_PERMISSION,
} from "@prisma/client";
import {
  CATEGORIES_MASTER,
  CATEGORY_LABELS,
  ENUM_AUTHORIZATION_METHOD,
  OPERATIONAL_HOURS_TIMES,
  STATE_CODES,
} from "@roadflex/constants";
import { AdminModalLoader } from "@roadflex/react-components-www";
import {
  Card,
  CardDetails,
  CardMerchants,
  CardRestrictionFormikValues,
  ChildFeatureType,
  FleetDriverFilterType,
  FleetFilterType,
  GlobalSettingType,
  OperationalHours,
  ParameterFilterType,
  TabsType,
} from "@roadflex/types";
import { humanize, humanizeEnum } from "@roadflex/utils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import { FormikProps } from "formik";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { InputSwitch } from "primereact/inputswitch";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { CustomMultiSelect, Select } from "../../dropdowns";
import { CheckboxInput, TextInput } from "../../inputs";
import { ConfirmModal, InfoModal } from "../../modals";
import { FuelCardComponentPermissionGuard } from "../../permission-guard";
import { DynamicPurchaseControls } from "../dynamic-purchase-controls/dynamic-purchase-controls";
import CardInformationHighNoteUI from "./card-information-highnote.ui";
import CardInformationUI from "./card-information.ui";
import CardMerchantDisclosureUI from "./card-merchant-disclosure.ui";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = publicRuntimeConfig;

const stripeResponse = loadStripe(`${NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);

interface cardsUIProps extends FormikProps<CardRestrictionFormikValues> {
  cardDetailsObj: Card;
  updateCardStatus: (card: Card) => void;
  editLimits: boolean;
  setEditLimits: (value: boolean) => void;
  editCardRules: boolean;
  setEditCardRules: (value: boolean) => void;
  adminCardSettingData: GlobalSettingType;
  editType: string;
  setEditType: (value: string) => void;
  showConfirmation: boolean;
  setConfirmation: (value: boolean) => void;
  variables: { var1: number; var2: number; var3: number };
  submittingRequest: boolean;
  submittingCardOrderRequest: boolean;
  handleCardUpdate: (cardDetails: CardDetails) => void;
  filterLoading: boolean;
  driversList: FleetDriverFilterType[];
  vehiclesList: FleetFilterType[];
  proceedToHandle: (cardStatus: string) => boolean;
  isAdmin?: boolean;
  tabs: TabsType[];
  childFeatures: ChildFeatureType[];
  defaultMerchants: CardMerchants[];
  handlePhysicalCardOrder: () => void;
  editedOperationalHours: OperationalHours[];
  setEditedOperationalHours: Dispatch<SetStateAction<OperationalHours[]>>;
  handleSubmitManually: () => void;
  overallToggle: boolean;
  handleOverallToggle: (checked: boolean) => void;
  handleCancel: () => void;
  handleMerchantChange: <K extends keyof CardMerchants>(
    merchantType: CardMerchants,
    value: CardMerchants[K],
    updateField: K,
  ) => void;
  handleCheckboxChange: (dayOfWeek: string, checked: boolean) => void;
  cardTags: ParameterFilterType[];
  cardSharingControls: ENUM_CARD_SHARING;
  cardCancelhandleSubmit: () => void;
  cardCancelsetFieldValue: (field: string, value: string) => void;
  cardCancelIsSubmitting: boolean;
  showCancelConfirmation: boolean;
  setCancelConfirmation: (value: boolean) => void;
  customerType: ENUM_CUSTOMER_TYPE;
}

// eslint-disable-next-line max-lines-per-function
export const CardDetailsPageUI = ({
  cardDetailsObj,
  updateCardStatus,
  editLimits,
  setEditLimits,
  editCardRules,
  setEditCardRules,
  variables,
  adminCardSettingData,
  editType,
  setEditType,
  showConfirmation,
  setConfirmation,
  submittingRequest,
  submittingCardOrderRequest,
  handleCardUpdate,
  filterLoading,
  driversList,
  vehiclesList,
  proceedToHandle,
  isAdmin = false,
  tabs,
  childFeatures,
  defaultMerchants,
  handlePhysicalCardOrder,
  editedOperationalHours,
  setEditedOperationalHours,
  handleSubmitManually,
  overallToggle,
  handleOverallToggle,
  handleCancel,
  handleMerchantChange,
  handleCheckboxChange,
  handleChange,
  handleSubmit,
  handleBlur,
  values,
  touched,
  errors,
  setFieldValue,
  resetForm,
  validateForm,
  setFieldTouched,
  cardTags,
  cardSharingControls,
  cardCancelhandleSubmit,
  cardCancelsetFieldValue,
  cardCancelIsSubmitting,
  showCancelConfirmation,
  setCancelConfirmation,
  customerType,
  ...rest
}: cardsUIProps) => {
  const [tooltip, settooltip] = useState(false);
  const router = useRouter();
  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);
  const [tabOpen, setTabOpen] = useState(1);
  const driverTemplate = (driver: FleetDriverFilterType) => {
    return <div>{driver.name}</div>;
  };
  const vehicleTemplate = (fleet: FleetFilterType) => {
    return <div>{fleet.fleetName}</div>;
  };

  const backToCardsPanel = () => {
    if (cardDetailsObj.cardType === "VIRTUAL") {
      router.push("/virtual-cards");
    } else {
      router.push("/cards");
    }
  };

  const confirmCardStatusChange = async () => {
    updateCardStatus(cardDetailsObj);
    setConfirmation(false);
  };
  const JSONObject = JSON.parse(JSON.stringify(cardDetailsObj?.metaData));

  let enableEdit: boolean =
    cardTags.length === 0 ||
    cardTags?.filter((x) => x.tag === cardDetailsObj?.accountTag?.id).length ===
      0 ||
    cardTags?.filter((x) => x.tag === cardDetailsObj?.accountTag?.id)[0]
      ?.readWritePermission === ENUM_USERS_READ_WRITE_PERMISSION.READ_AND_WRITE;

  if (JSONObject) {
    enableEdit = false;
  }

  useEffect(() => {
    if (cardDetailsObj.cardType === "VIRTUAL") {
      setTabOpen(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetailsObj.cardType]);

  const [fuelExpensesCategories, setFuelExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [businessExpensesCategories, setBusinessExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [vehicleExpensesCategories, setVehicleExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [otherCategories, setOtherCategories] = useState<CardMerchants[]>([]);
  const [otherMerchantCategories, setOtherMerchantCategories] = useState<
    CardMerchants[]
  >([]);
  useEffect(() => {
    const fuelExpenses: CardMerchants[] = [];
    const businessExpenses: CardMerchants[] = [];
    const vehicleExpenses: CardMerchants[] = [];
    const other: CardMerchants[] = [];
    const otherOption: CardMerchants[] = [];

    if (cardDetailsObj?.merchants && defaultMerchants.length > 0) {
      cardDetailsObj.merchants.forEach((merchant) => {
        if (
          merchant.classification === ENUM_CLASSIFICATION.FUEL &&
          merchant.authorized
        ) {
          fuelExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.BUSINESS &&
          merchant.authorized
        ) {
          businessExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.VEHICLE &&
          merchant.authorized
        ) {
          if (merchant.name !== "auto_service_shops") {
            vehicleExpenses.push(merchant);
          }
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name !== "others"
        ) {
          other.push(merchant);
        }

        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name === "others"
        ) {
          otherOption.push(merchant);
        }
      });
    }
    fuelExpenses.sort((a, b) => a.name.localeCompare(b.name));
    businessExpenses.sort((a, b) => a.name.localeCompare(b.name));
    vehicleExpenses.sort((a, b) => a.name.localeCompare(b.name));
    other.sort((a, b) => a.name.localeCompare(b.name));
    setFuelExpensesCategories(fuelExpenses);
    setBusinessExpensesCategories(businessExpenses);
    setVehicleExpensesCategories(vehicleExpenses);
    setOtherCategories(other);
    setOtherMerchantCategories(otherOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(values.merchants || []).length]);

  const access = {
    PinManagementAccess:
      childFeatures.find(
        (item) => item.name === "CARD_PAGE_DETAILS_PIN_MANAGEMENT",
      ) !== undefined,
    DynamicPurchaseControl:
      childFeatures.find(
        (item) => item.name === "CARD_PAGE_DETAILS_DYNAMIC_PURCHASE_CONTROLS",
      ) !== undefined,
    CardRulesAccess:
      childFeatures.find(
        (item) => item.name === "CARD_PAGE_DETAILS_CARD_RULES",
      ) !== undefined,
    OperationalHoursAccess:
      childFeatures
        .find((item) => item.name === "CARD_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name === "CARD_PAGE_DETAILS_CARD_RULES_OPERATIONAL_HOURS",
        ) !== undefined,
    CardAssignmentsAccess:
      childFeatures
        .find((item) => item.name === "CARD_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name === "CARD_PAGE_DETAILS_CARD_RULES_CARD_ASSIGNMENTS",
        ) !== undefined,
    RestrictPurchaseAccess:
      childFeatures
        .find((item) => item.name === "CARD_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name === "CARD_PAGE_DETAILS_CARD_RULES_RESTRICT_PURCHASE",
        ) !== undefined,
    TransactionControlsAccess:
      childFeatures
        .find((item) => item.name === "CARD_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name === "CARD_PAGE_DETAILS_CARD_RULES_TRANSACTION_CONTROLS",
        ) !== undefined,
    SpendLimitsAccess:
      childFeatures.find(
        (item) => item.name === "CARD_PAGE_DETAILS_SPEND_LIMITS",
      ) !== undefined,
    LimitsAccess:
      childFeatures
        .find((item) => item.name === "CARD_PAGE_DETAILS_SPEND_LIMITS")
        ?.grandchildren?.find(
          (item) => item.name === "CARD_PAGE_DETAILS_SPEND_LIMITS_SPEND_LIMITS",
        ) !== undefined,
    CategoryRestrictionsAccess:
      childFeatures
        .find((item) => item.name === "CARD_PAGE_DETAILS_SPEND_LIMITS")
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "CARD_PAGE_DETAILS_SPEND_LIMITS_CATEGORY_RESTRICTIONS",
        ) !== undefined,
  };

  return (
    <div className="flex-col flex flex-1 pb-14 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)] md:pb-4 md:px-14">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between gap-3 md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.GrayOutline}
                  onClick={backToCardsPanel}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg">
                Card &#xB7;&#xB7;&#xB7;&#xB7; {cardDetailsObj.lastFour}
                <span
                  className={classNames(
                    cardDetailsObj.status === "active"
                      ? "text-green-700 py-1 px-2"
                      : "text-red-600 py-1 px-2",
                    "ml-4 uppercase",
                  )}
                >
                  {cardDetailsObj.status}
                </span>
              </div>
            </div>

            <div>
              <Button
                onClick={() => {
                  const val = proceedToHandle(
                    cardDetailsObj.status === "active" ? "inactive" : "active",
                  );
                  if (val === false) {
                    updateCardStatus(cardDetailsObj);
                  }
                }}
                size={ButtonSize.AppSize}
                variant={
                  cardDetailsObj.status === "active"
                    ? ButtonVariant.Red
                    : ButtonVariant.Green
                }
                loading={submittingRequest}
                disabled={submittingRequest}
                className={classNames(
                  cardDetailsObj.status === "active"
                    ? "text-sm md:text-base"
                    : "text-sm md:text-base",
                )}
              >
                {cardDetailsObj.status === "active"
                  ? "Deactivate Card"
                  : "Activate Card"}
              </Button>
            </div>
          </div>
        </div>
        <nav className="flex -mb-px space-x-4 overflow-auto md:space-x-8">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tabOpen === tab.id
                  ? "border-black text-black"
                  : "border-transparent hover:border-black",
                "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
              )}
              onClick={(e) => {
                e.preventDefault();
                setTabOpen(tab.id);
              }}
            >
              {tab.name === "Pin Management" ||
              tab.name === "Dynamic Purchase Controls" ? (
                <>
                  {tab.name}{" "}
                  <span className="text-yellow-600">(Enterprise)</span>
                </>
              ) : (
                <>{tab.name}</>
              )}
            </a>
          ))}
        </nav>
        <form onSubmit={handleSubmitManually} className="w-full mt-5 md:mt-0">
          <div className="border-b border-brown-500"></div>
          <div className={`${tabOpen === 0 ? `block` : `hidden`} mt-4 md:mt-6`}>
            {cardDetailsObj?.platform === ENUM_PLATFORM_TYPE.HIGHNOTE ? (
              <CardInformationHighNoteUI
                cardDetailsObj={cardDetailsObj}
                handlePhysicalCardOrder={handlePhysicalCardOrder}
                submittingCardOrderRequest={submittingCardOrderRequest}
              ></CardInformationHighNoteUI>
            ) : (
              <Elements stripe={stripeResponse} options={{}}>
                <CardInformationUI
                  cardDetailsObj={cardDetailsObj}
                  access={(access?.PinManagementAccess && enableEdit) || false}
                ></CardInformationUI>
              </Elements>
            )}
          </div>

          {access.CardRulesAccess && (
            <div
              className={`${tabOpen === 1 ? `block` : `hidden`} mt-4 md:mt-6`}
            >
              <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
                  <div className="px-4 font-medium">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Operational Hours{" "}
                      <span className="font-normal text-yellow-600">
                        (Premium)
                      </span>
                    </div>
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="flex flex-row items-center w-full px-4">
                    <div className="w-full">
                      <div className="flex flex-row items-center justify-between w-full mb-4">
                        <div className="text-left">
                          Limit spending by days and times in PST?{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="ml-4 text-left">
                          <FuelCardComponentPermissionGuard
                            type="inputswitch"
                            featurePermission={access.OperationalHoursAccess}
                            messageType="premium"
                          >
                            <InputSwitch
                              name="enableOperationalHours"
                              checked={values.enableOperationalHours}
                              onChange={handleChange}
                              disabled={!editCardRules}
                              className="border-0 input-switch"
                            />
                          </FuelCardComponentPermissionGuard>
                        </div>
                      </div>
                      <div className="flex flex-col divide-y divide-gray-300">
                        {editedOperationalHours.map((dayOperationalHours) => {
                          const disableEdit = !(
                            dayOperationalHours.isEnabled &&
                            values.enableOperationalHours &&
                            editCardRules
                          );
                          return (
                            <div
                              key={dayOperationalHours.dayOfWeek}
                              className={classNames(
                                dayOperationalHours.isEnabled
                                  ? "text-black"
                                  : "text-gray-500",
                                "flex flex-col gap-3 sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-start lg:items-center justify-between py-4 first:pt-0 last:pb-0 border-gray-300",
                              )}
                            >
                              <div className="flex flex-row items-center">
                                <CheckboxInput
                                  label={dayOperationalHours.dayOfWeek.toLowerCase()}
                                  labelClass="capitalize"
                                  id={`isEnabled-${dayOperationalHours.dayOfWeek}`}
                                  type="checkbox"
                                  name={`isEnabled-${dayOperationalHours.dayOfWeek}`}
                                  checked={dayOperationalHours.isEnabled}
                                  disabled={
                                    !values.enableOperationalHours ||
                                    !editCardRules
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      dayOperationalHours.dayOfWeek,
                                      e.target.checked,
                                    )
                                  }
                                ></CheckboxInput>
                              </div>
                              <div className="flex flex-row items-center">
                                <Select
                                  id="start-time"
                                  value={dayOperationalHours.startTime}
                                  disabled={disableEdit}
                                  onChange={(e) =>
                                    setEditedOperationalHours((prev) =>
                                      prev.map((oh) =>
                                        oh.dayOfWeek ===
                                        dayOperationalHours.dayOfWeek
                                          ? {
                                              ...oh,
                                              startTime: e.target.value,
                                            }
                                          : oh,
                                      ),
                                    )
                                  }
                                  placeholder="-- Select --"
                                  className="w-[120px]"
                                  optionLabel="label"
                                  optionValue="value"
                                  options={[
                                    {
                                      label: "-- Select --",
                                      value: "",
                                    },
                                  ].concat(
                                    OPERATIONAL_HOURS_TIMES.map((time) => {
                                      return { label: time, value: time };
                                    }),
                                  )}
                                ></Select>
                                <label className="m-4">to</label>
                                <Select
                                  id="end-time"
                                  value={dayOperationalHours.endTime}
                                  disabled={disableEdit}
                                  onChange={(e) =>
                                    setEditedOperationalHours((prev) =>
                                      prev.map((oh) =>
                                        oh.dayOfWeek ===
                                        dayOperationalHours.dayOfWeek
                                          ? {
                                              ...oh,
                                              endTime: e.target.value,
                                            }
                                          : oh,
                                      ),
                                    )
                                  }
                                  placeholder="-- Select --"
                                  className="w-[120px]"
                                  optionLabel="label"
                                  optionValue="value"
                                  options={[
                                    {
                                      label: "-- Select --",
                                      value: "",
                                    },
                                  ].concat(
                                    OPERATIONAL_HOURS_TIMES.slice(
                                      OPERATIONAL_HOURS_TIMES.findIndex(
                                        (time) =>
                                          time ===
                                          dayOperationalHours.startTime,
                                      ),
                                    ).map((time) => {
                                      return { label: time, value: time };
                                    }),
                                  )}
                                ></Select>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cardDetailsObj.blockedMerchantsNetworkIds &&
                cardDetailsObj.blockedMerchantsNetworkIds.length > 0 && (
                  <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                    <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
                      <div className="px-4 font-medium">
                        <div className="font-semibold text-brown-500 md:text-lg">
                          Blocked Merchant Ids
                        </div>
                      </div>
                      <div className="h-0.5 border-0 bg-brown-500"></div>
                      <div className="flex flex-col w-full gap-2 px-4">
                        {cardDetailsObj.blockedMerchantsNetworkIds?.map(
                          (merchant) => (
                            <div
                              key={merchant.networkId}
                              className="flex items-center justify-between p-2 bg-gray-200 rounded hover:bg-gray-300"
                            >
                              <span className="">
                                {merchant.networkId}{" "}
                                <span className="text-gray-500">
                                  ({merchant.merchantName})
                                </span>
                              </span>
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                )}
              <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
                  <div className="px-4 font-medium">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Restrict Purchase States{" "}
                      <span className="font-normal text-yellow-600">
                        (Enterprise)
                      </span>
                    </div>
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="flex flex-row items-center w-full px-4">
                    <div className="w-full">
                      <div className="mb-2 text-left">
                        Block purchases from these US states
                      </div>
                      <div className="flex flex-row items-center justify-between w-full mb-4">
                        <FuelCardComponentPermissionGuard
                          type="checkbox"
                          featurePermission={access.RestrictPurchaseAccess}
                          messageType="enterprise"
                        >
                          <CustomMultiSelect
                            name="state"
                            id="state"
                            className="border-none"
                            disabled={!editCardRules}
                            value={
                              values.blockState
                                ? values.blockState.split(",")
                                : []
                            }
                            onChange={(e) => {
                              setFieldValue(
                                "blockState",
                                e.target.value.join(","),
                              );
                            }}
                            options={STATE_CODES.map((m) => {
                              return {
                                label: `${m.label} (${m.value})`,
                                value: m.value,
                                key: m.value,
                                disabled: false,
                              };
                            })}
                          ></CustomMultiSelect>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
                  <div className="px-4 font-medium">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Allowed Authorization Types
                      <span className="font-normal text-yellow-600"></span>
                    </div>
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="flex flex-row items-center w-full px-4">
                    <div className="w-full">
                      <div className="mb-2 text-left">Authorization Types</div>
                      <div className="flex flex-row items-center justify-between w-full mb-4">
                        <FuelCardComponentPermissionGuard
                          type="checkbox"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <CustomMultiSelect
                            value={values.authorizationMethod}
                            options={[
                              {
                                label: "-- Select multiple options --",
                                value: "",
                                disabled: true,
                              },
                            ].concat(
                              Object.keys(ENUM_AUTHORIZATION_METHOD).map(
                                (categories) => {
                                  const label: string =
                                    ENUM_AUTHORIZATION_METHOD[categories];
                                  return {
                                    label: humanize(label),
                                    value: categories,
                                    disabled: false,
                                  };
                                },
                              ),
                            )}
                            onChange={(e) =>
                              setFieldValue("authorizationMethod", e.value)
                            }
                            placeholder="-- Select multiple options --"
                            optionLabel="label"
                            optionValue="value"
                            maxSelectedLabels={1}
                            disabled={!editCardRules}
                          ></CustomMultiSelect>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                  {" "}
                  <div className="px-4 font-semibold text-brown-500 md:text-lg ">
                    Card Assignments
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="flex flex-col gap-4 px-4 divide-y divide-gray-300">
                    {cardSharingControls === ENUM_CARD_SHARING.NONE && (
                      <div className="flex flex-row items-center justify-between gap-4">
                        <div className="">Driver</div>
                        <FuelCardComponentPermissionGuard
                          type="dropdown"
                          featurePermission={access.CardAssignmentsAccess}
                        >
                          <Select
                            id="driverId"
                            value={values.driverId}
                            options={[
                              { id: "", name: "-", createdAt: new Date() },
                            ].concat(driversList)}
                            onChange={(e) => {
                              setFieldValue("driverId", e.value);
                            }}
                            placeholder="No Driver Selected"
                            className="min-w-[150px] max-w-[300px]"
                            optionLabel="name"
                            optionValue="id"
                            itemTemplate={driverTemplate}
                            disabled={isAdmin || !editCardRules}
                          ></Select>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    )}
                    <div className="flex flex-row items-center justify-between gap-4 pt-4">
                      <div className="">Vehicle</div>
                      <FuelCardComponentPermissionGuard
                        type="dropdown"
                        featurePermission={access.CardAssignmentsAccess}
                      >
                        <Select
                          id="vehicleId"
                          value={values.vehicleId}
                          options={[
                            {
                              id: "",
                              fleetName: "-",
                              createdAt: new Date(),
                              eldOrgName: "",
                              tankCapacity: 0,
                            },
                          ].concat(vehiclesList)}
                          onChange={(e) => {
                            setFieldValue("vehicleId", e.value);
                          }}
                          placeholder="No Vehicle Selected"
                          className="min-w-[150px] max-w-[300px]"
                          optionLabel="fleetName"
                          optionValue="id"
                          itemTemplate={vehicleTemplate}
                          disabled={isAdmin || !editCardRules}
                        ></Select>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                </div>
              </div>
              {cardSharingControls === ENUM_CARD_SHARING.VEHICLE_SHARING && (
                <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                  <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                    {" "}
                    <div className="px-4 font-semibold text-brown-500 md:text-lg ">
                      Vehicle Sharing Card Unlock
                    </div>
                    <div className="h-0.5 border-0 bg-brown-500"></div>
                    <div className="flex flex-col px-4 ">
                      <div className="text-sm md:text-base">
                        <div className="mb-3">
                          <div className="mb-5">
                            Once enabled, only the assigned vehicle will be able
                            to use this card. Before using the card, the driver
                            must verify their identity via the following method:
                          </div>
                        </div>
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-row">
                            <div className="p-2 mr-2 bg-gray-200 rounded-md h-fit">
                              <ChatBubbleOvalLeftEllipsisIcon
                                className="w-10 h-10"
                                aria-hidden="true"
                              ></ChatBubbleOvalLeftEllipsisIcon>
                            </div>
                            <div className="">
                              <div className="font-medium">Unlock via SMS</div>
                              <div className="">
                                Text the last 4 digits of your card followed by
                                the expiry date in MMYY format (optional) and
                                Driver Tag ID (optional) separated by a comma to
                                (315) 888-5469. The card will be unlocked for 15
                                minutes. Some acceptable formats are:
                                <ul className="mt-2 list-disc list-inside">
                                  <li>4575 (Only last four digits provided)</li>
                                  <li>
                                    4575, 1228 (Only last four digits and MMYY
                                    provided)
                                  </li>
                                  <li>
                                    4575, 1228, 1ABC234 (Last four digits, MMYY
                                    and Driver Tag ID provided)
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="ml-3 text-base">
                            <FuelCardComponentPermissionGuard
                              type="inputswitch"
                              featurePermission={
                                access.TransactionControlsAccess
                              }
                              messageType="premium"
                            >
                              <InputSwitch
                                checked={values.smsSecurity}
                                name="smsSecurity"
                                id="smsSecurity"
                                disabled={!editCardRules}
                                onChange={handleChange}
                                className="ml-4 border-0 input-switch disabled:opacity-50"
                              />
                              {values.smsSecurity && (
                                <div className="ml-2">Active</div>
                              )}
                              {!values.smsSecurity && (
                                <div className="ml-2">Inactive</div>
                              )}
                            </FuelCardComponentPermissionGuard>
                          </div>
                        </div>
                      </div>
                      {errors.smsSecurity && (
                        <div className="mt-2 text-sm text-red-500">
                          {errors.smsSecurity}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                  <div className="px-4 font-medium border-gray-300 ">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Transaction Controls{" "}
                      <span className="font-normal text-yellow-600">
                        (Premium)
                      </span>
                    </div>
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="flex flex-col px-4 divide-y divide-gray-300 ">
                    {cardSharingControls === ENUM_CARD_SHARING.NONE && (
                      <div className="flex flex-row justify-between pb-4">
                        <div className="flex flex-col">
                          <div className="">
                            Card unlock through SMS or driver app{" "}
                            <span className="font-normal text-yellow-600">
                              (Premium)
                            </span>
                          </div>
                          <div className="mt-2 text-sm">
                            Ensure card can only be used by a verified driver
                          </div>
                          <div className="mt-2 text-sm">
                            <Button
                              onClick={() => {
                                settooltip(true);
                              }}
                              variant={ButtonVariant.Transparent}
                              size={ButtonSize.Wrap}
                              className="text-orange-500"
                            >
                              {/* <ExclamationCircleIcon
                            className="w-5 h-5 text-yellow-600"
                            aria-hidden="true"
                          ></ExclamationCircleIcon> */}
                              How it works?
                            </Button>
                            {tooltip && (
                              <InfoModal
                                title={"Card Unlock Security"}
                                body={
                                  <div className="text-sm md:text-base">
                                    <div className="mb-3">
                                      <div className="mb-5">
                                        By enabling, only verified users will be
                                        able to use this card, so make sure they
                                        are added on the driver&apos;s page.
                                        Before using the card, drivers must
                                        verify their identity via the following
                                        method:
                                      </div>
                                    </div>
                                    <div className="flex flex-row">
                                      <div className="p-2 mr-2 bg-gray-200 rounded-md h-fit">
                                        <ChatBubbleOvalLeftEllipsisIcon
                                          className="w-10 h-10"
                                          aria-hidden="true"
                                        ></ChatBubbleOvalLeftEllipsisIcon>
                                      </div>
                                      <div>
                                        <div className="font-medium">
                                          Unlock via SMS
                                        </div>
                                        <div className="">
                                          Text the last 4 digits of your card to
                                          (315) 888-5469. The card will be
                                          unlocked for 15 minutes.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                                setShow={settooltip}
                                show={tooltip}
                              ></InfoModal>
                            )}
                            {errors.smsSecurity && (
                              <div className="mt-2 text-sm text-red-500">
                                {errors.smsSecurity}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-base">
                          <FuelCardComponentPermissionGuard
                            type="inputswitch"
                            featurePermission={access.TransactionControlsAccess}
                            messageType="premium"
                          >
                            <InputSwitch
                              checked={values.smsSecurity}
                              name="smsSecurity"
                              id="smsSecurity"
                              disabled={!editCardRules}
                              onChange={handleChange}
                              className="ml-4 border-0 input-switch disabled:opacity-50"
                            />
                            {values.smsSecurity && (
                              <div className="ml-2">Active</div>
                            )}
                            {!values.smsSecurity && (
                              <div className="ml-2">Inactive</div>
                            )}
                          </FuelCardComponentPermissionGuard>
                        </div>
                      </div>
                    )}
                    <div
                      className={`flex flex-row justify-between ${
                        cardSharingControls === ENUM_CARD_SHARING.NONE
                          ? "py-4"
                          : "pb-4"
                      }`}
                    >
                      <div className="flex flex-col">
                        <div className="">
                          Require an odometer reading before every fuel
                          transaction{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Odometer reading needs to be submitted to unlock the
                          card
                        </div>
                        {errors.odometerSecurity && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.odometerSecurity}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <InputSwitch
                            checked={values.odometerSecurity}
                            name="odometerSecurity"
                            id="odometerSecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.odometerSecurity && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.odometerSecurity && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Require a transaction tag ID{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          {" "}
                          Drivers will be prompted for a transaction tag ID in
                          the driver app after the card is swiped
                        </div>
                        {errors.requireJobID && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.requireJobID}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <InputSwitch
                            checked={values.requireJobID}
                            name="requireJobID"
                            id="requireJobID"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.requireJobID && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.requireJobID && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Enable receipt upload via SMS{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          If receipt is required for purchase based on "spend
                          limits" customizations, drivers will have the option
                          to upload receipts via text
                        </div>
                        {errors.enableSmsReceiptUpload && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.enableSmsReceiptUpload}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <InputSwitch
                            checked={values.enableSmsReceiptUpload}
                            name="enableSmsReceiptUpload"
                            id="enableSmsReceiptUpload"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.enableSmsReceiptUpload && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.enableSmsReceiptUpload && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Flag for wrong fuel type{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Transactions will be flagged if the purchased fuel
                          type doesn't match the fuel type in vehicle settings
                        </div>
                        {errors.fuelTypeSecurity && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.fuelTypeSecurity}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <InputSwitch
                            checked={values.fuelTypeSecurity}
                            name="fuelTypeSecurity"
                            id="fuelTypeSecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.fuelTypeSecurity && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.fuelTypeSecurity && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Flag when fuel purchase exceeds tank capacity{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          {" "}
                          Transactions will be flagged if the fuel purchased is
                          greater than the vehicle tank capacity
                        </div>
                        {errors.tankCapacitySecurity && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.tankCapacitySecurity}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <InputSwitch
                            checked={values.tankCapacitySecurity}
                            name="tankCapacitySecurity"
                            id="tankCapacitySecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.tankCapacitySecurity && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.tankCapacitySecurity && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Flag when fuel level anomaly is detected/fuel theft
                          detected{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Transactions will be flagged if the fuel level within
                          the vehicle drops beyond an acceptable limit between 2
                          consecutive vehicle fueling
                        </div>
                        {errors.suspiciousFuelingSecurity && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.suspiciousFuelingSecurity}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <InputSwitch
                            checked={values.suspiciousFuelingSecurity}
                            name="suspiciousFuelingSecurity"
                            id="suspiciousFuelingSecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.suspiciousFuelingSecurity && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.suspiciousFuelingSecurity && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>

                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Flag transactions from specific merchant categories{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="checkbox"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <CustomMultiSelect
                            value={values.flaggedMerchantCategories}
                            options={[
                              {
                                label: "-- Select multiple options --",
                                value: "",
                                disabled: true,
                              },
                              {
                                label: "Auto Service Shops (7538)",
                                value: "auto_service_shops",
                                disabled: false,
                              },
                            ].concat(
                              CATEGORIES_MASTER.split(",").map((categories) => {
                                return {
                                  label:
                                    (CATEGORY_LABELS[categories] &&
                                      CATEGORY_LABELS[categories]?.label) ||
                                    humanize(categories),
                                  value: categories,
                                  disabled: false,
                                };
                              }),
                            )}
                            onChange={(e) =>
                              setFieldValue(
                                "flaggedMerchantCategories",
                                e.value,
                              )
                            }
                            placeholder="-- Select multiple options --"
                            optionLabel="label"
                            optionValue="value"
                            maxSelectedLabels={1}
                            disabled={!editCardRules}
                          ></CustomMultiSelect>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>

                    <div className="flex flex-row justify-between pt-4">
                      <div className="flex flex-col">
                        <div className="">
                          Alert/Decline transaction when vehicle is far away
                          from the merchant{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Connect a telematics provider to get started
                        </div>
                        {errors.telematicsSecurity && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.telematicsSecurity}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="dropdown"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <Select
                            value={values.telematicsSecurity}
                            disabled={!editCardRules}
                            onChange={(e) =>
                              setFieldValue("telematicsSecurity", e.value)
                            }
                            className="min-w-[150px]"
                            options={[
                              {
                                label: "--- Select ---",
                                value: "",
                                disabled: true,
                              },
                            ].concat(
                              Object.keys(ENUM_TELEMATICS_SECURITY)?.map(
                                (telemetics) => {
                                  if (
                                    telemetics ===
                                    ENUM_TELEMATICS_SECURITY?.BLOCK_BROAD_LOCATION
                                  ) {
                                    return {
                                      label:
                                        "Block by State, City, Postal Code (Less restrictive)",
                                      value: telemetics,
                                      disabled: false,
                                    };
                                  }
                                  return {
                                    label: humanizeEnum(telemetics),
                                    value: telemetics,
                                    disabled: false,
                                  };
                                },
                              ),
                            )}
                          ></Select>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {!editCardRules && (
                  <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-5">
                    <Button
                      type={ButtonType.Button}
                      size={ButtonSize.AppSize}
                      variant={ButtonVariant.Green}
                      disabled={!enableEdit}
                      onClick={() => {
                        setEditCardRules(true);
                      }}
                    >
                      <PencilIcon className="w-5 h-5" />
                      <span className="pl-2 text-base">
                        {enableEdit ? "Edit" : "Read Permission Only"}
                      </span>
                    </Button>
                  </div>
                )}
                {editCardRules && (
                  <div className="fixed flex flex-row justify-end gap-2 bottom-5 right-5">
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Red}
                      size={ButtonSize.AppSize}
                      disabled={submittingRequest}
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Black}
                      size={ButtonSize.AppSize}
                      disabled={submittingRequest}
                      loading={submittingRequest}
                      onClick={() => {
                        setEditType("CARD_SETTINGS");
                        handleSubmitManually();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}

          {access.SpendLimitsAccess && (
            <div
              className={`${tabOpen === 2 ? `block` : `hidden`} mt-4 md:mt-6`}
            >
              <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
                  <div className="px-4 font-medium border-gray-300 ">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Spend Limits
                    </div>
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="flex flex-col px-4 divide-y divide-gray-300">
                    <div className="flex flex-row items-center justify-between pb-4">
                      <div className="min-w-[125px]">Per Transaction Limit</div>
                      <div className="text-right">
                        <FuelCardComponentPermissionGuard
                          type="input"
                          featurePermission={access.LimitsAccess}
                        >
                          <TextInput
                            type="number"
                            className="max-w-[205px]"
                            min={0}
                            name="transactionLimit"
                            id="transactionLimit"
                            value={values.transactionLimit}
                            onChange={handleChange}
                            disabled={isAdmin || !editLimits}
                            onBlur={handleBlur}
                            placeholder="1000"
                            touched={touched.transactionLimit}
                            error={
                              touched.transactionLimit &&
                              Boolean(errors.transactionLimit)
                            }
                            errorMessage={errors.transactionLimit}
                          ></TextInput>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between py-4">
                      <div className="min-w-[125px]">Daily Limit</div>
                      <div className="text-right">
                        <FuelCardComponentPermissionGuard
                          type="input"
                          featurePermission={access.LimitsAccess}
                        >
                          <TextInput
                            type="number"
                            className="max-w-[205px]"
                            min={0}
                            name="dailyLimit"
                            id="dailyLimit"
                            value={values.dailyLimit}
                            onChange={handleChange}
                            disabled={isAdmin || !editLimits}
                            onBlur={handleBlur}
                            placeholder="100"
                            touched={touched.dailyLimit}
                            error={
                              touched.dailyLimit && Boolean(errors.dailyLimit)
                            }
                            errorMessage={errors.dailyLimit}
                          ></TextInput>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between py-4">
                      <div className="min-w-[125px]">Weekly Limit</div>
                      <div className="text-right">
                        <FuelCardComponentPermissionGuard
                          type="input"
                          featurePermission={access.LimitsAccess}
                        >
                          <TextInput
                            type="number"
                            className="max-w-[205px]"
                            min={0}
                            name="weeklyLimit"
                            id="weeklyLimit"
                            value={values.weeklyLimit}
                            onChange={handleChange}
                            disabled={isAdmin || !editLimits}
                            onBlur={handleBlur}
                            placeholder="1000"
                            touched={touched.weeklyLimit}
                            error={
                              touched.weeklyLimit && Boolean(errors.weeklyLimit)
                            }
                            errorMessage={errors.weeklyLimit}
                          ></TextInput>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between pt-4">
                      <div className="min-w-[125px]">Monthly Limit</div>
                      <div className="capitalize">
                        <FuelCardComponentPermissionGuard
                          type="input"
                          featurePermission={access.LimitsAccess}
                        >
                          <TextInput
                            type="number"
                            className="max-w-[205px]"
                            min={0}
                            name="monthlyLimit"
                            id="monthlyLimit"
                            value={values.monthlyLimit}
                            onChange={handleChange}
                            disabled={isAdmin || !editLimits}
                            onBlur={handleBlur}
                            placeholder="5000"
                            touched={touched.monthlyLimit}
                            error={
                              touched.monthlyLimit &&
                              Boolean(errors.monthlyLimit)
                            }
                            errorMessage={errors.monthlyLimit}
                          ></TextInput>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
                <div className="flex flex-col overflow-hidden">
                  <div className="flex flex-row items-center justify-between gap-2 px-4 mb-4 md:mb-8">
                    <div className="pr-12">
                      <div className="font-semibold text-brown-500 md:text-lg">
                        Category Restrictions{" "}
                        <span className="font-normal text-yellow-600">
                          {" "}
                          (Premium)
                        </span>
                      </div>
                      <div className="mt-2 text-sm">
                        Block or restrict spending on this card to specific
                        merchant categories. These categories are based on
                        Merchant Category Codes, which are determined by the
                        merchant and confirmed by the Visa payments network.
                        Merchant categories could be miscategorized. This is
                        outside of RoadFlex's control.
                      </div>
                    </div>
                    <div className="flex items-center h-6 px-4">
                      <InputSwitch
                        id="overallToggle"
                        name="overallToggle"
                        checked={overallToggle}
                        disabled={!editLimits}
                        onChange={(e) => handleOverallToggle(e.value)} // Handle overall switch toggle
                        className="border-0 input-switch"
                      />
                    </div>
                  </div>
                  <div className="h-0.5 border-0 bg-brown-500"></div>
                  <div className="px-4">
                    <fieldset className="space-y-0">
                      {fuelExpensesCategories?.length > 0 && (
                        <>
                          <div className="pt-4 text-left md:pt-8">
                            Fuel Expenses
                          </div>
                          {fuelExpensesCategories.map((merchantType) => (
                            <div key={merchantType.name}>
                              <CardMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  handleSubmit,
                                  resetForm,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  validateForm,
                                  setFieldTouched,
                                  ...rest,
                                }}
                              ></CardMerchantDisclosureUI>
                            </div>
                          ))}
                        </>
                      )}

                      {vehicleExpensesCategories?.length > 0 && (
                        <>
                          <div className="pt-4 text-left md:pt-8">
                            Vehicle Expenses
                          </div>
                          {vehicleExpensesCategories.map((merchantType) => (
                            <div key={merchantType.name}>
                              <CardMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  resetForm,
                                  handleSubmit,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  validateForm,
                                  setFieldTouched,
                                  ...rest,
                                }}
                              ></CardMerchantDisclosureUI>
                            </div>
                          ))}
                        </>
                      )}
                      {businessExpensesCategories.length > 0 && (
                        <>
                          <div className="pt-4 text-left md:pt-8">
                            Business Expenses
                          </div>
                          {businessExpensesCategories.map((merchantType) => (
                            <div key={merchantType.name}>
                              <CardMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  resetForm,
                                  handleSubmit,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  validateForm,
                                  setFieldTouched,
                                  ...rest,
                                }}
                              ></CardMerchantDisclosureUI>
                            </div>
                          ))}
                        </>
                      )}
                      {(otherCategories?.length > 0 ||
                        otherMerchantCategories?.length > 0) && (
                        <>
                          <div className="w-full mb-4">
                            <div className="pt-4 font-medium text-left">
                              Additional Merchants Enabled
                            </div>
                            <div className="mt-2 text-xs italic text-left text-gray-500">
                              Additional merchant types that are opened up for
                              your account
                            </div>
                          </div>
                          {otherCategories.map((merchantType) => (
                            <div key={merchantType.name}>
                              <CardMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  resetForm,
                                  handleSubmit,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  validateForm,
                                  setFieldTouched,
                                  ...rest,
                                }}
                              ></CardMerchantDisclosureUI>
                            </div>
                          ))}
                          {otherMerchantCategories.map((merchantType) => (
                            <div
                              className="relative flex items-center justify-between py-4 border-b divide-gray-300"
                              key={merchantType.name}
                            >
                              <div className="pl-4 ml-4 mr-3 text-sm text-left">
                                <label htmlFor={merchantType.name} className="">
                                  {(CATEGORY_LABELS[merchantType.name] &&
                                    CATEGORY_LABELS[merchantType.name]
                                      ?.label) ||
                                    humanize(merchantType.name)}
                                </label>
                              </div>
                              <div className="flex items-center h-6 pr-4">
                                <InputSwitch
                                  id={merchantType.name}
                                  name={merchantType.name}
                                  checked={
                                    values?.merchants?.filter(
                                      (value) =>
                                        value.name === merchantType.name,
                                    )[0].toggleOn
                                  }
                                  onChange={(e) =>
                                    handleMerchantChange(
                                      merchantType,
                                      e.target.value,
                                      "toggleOn",
                                    )
                                  }
                                  disabled={!editLimits}
                                  className="border-0 input-switch"
                                />
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>
              <div>
                {!editLimits && (
                  <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-5">
                    <Button
                      type={ButtonType.Button}
                      size={ButtonSize.AppSize}
                      variant={ButtonVariant.Green}
                      onClick={() => {
                        setEditLimits(true);
                      }}
                      disabled={!enableEdit}
                    >
                      <PencilIcon className="w-5 h-5" />
                      <span className="pl-2 text-base">
                        {enableEdit ? "Edit" : "Read Permission Only"}
                      </span>
                    </Button>
                  </div>
                )}
                {editLimits && (
                  <div className="fixed flex flex-row justify-end gap-2 bottom-5 right-5">
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Red}
                      size={ButtonSize.AppSize}
                      disabled={submittingRequest}
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Black}
                      size={ButtonSize.AppSize}
                      disabled={submittingRequest}
                      loading={submittingRequest}
                      onClick={() => {
                        setEditType("LIMITS_AND_MERCHANTS");
                        handleSubmitManually();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className={`${tabOpen === 3 ? `block` : `hidden`} mt-4 md:mt-6`}>
            <DynamicPurchaseControls
              object="card"
              id={cardDetailsObj.id}
              access={access.DynamicPurchaseControl}
              enableEdit={enableEdit}
              createMode={false}
            ></DynamicPurchaseControls>
          </div>
        </form>
        <div className={`${tabOpen === 4 ? `block` : `hidden`} mt-4 md:mt-6`}>
          <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
            <div className="flex flex-col overflow-hidden">
              <div className="flex flex-row items-center justify-between gap-2 px-4 mb-4 md:mb-8">
                <div>View transactions for this card</div>
                <a
                  href={`/transactions?filter=card&value=${cardDetailsObj.lastFour}`}
                  className="whitespace-nowrap"
                >
                  <span className="flex items-center gap-1">
                    <LinkIcon className="w-3 h-3" />
                  </span>
                </a>
              </div>
              <div className="flex flex-row items-center justify-between gap-2 px-4 mb-4 md:mb-8">
                <div>Cancel Card (March Feature Release)</div>
                <div>
                  <Button
                    variant={ButtonVariant.Brown}
                    size={ButtonSize.Small}
                    className=""
                    onClick={() => {
                      setFieldValue("id", cardDetailsObj?.id);
                      setCancelConfirmation(true);
                    }}
                    disabled={true}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminModalLoader
        showLoading={submittingCardOrderRequest || submittingRequest}
      />

      {showConfirmation && (
        <ConfirmModal
          title={"Confirm activating card(s)"}
          body={
            <div className="font-medium">
              <div className="">
                <div className="mb-3">
                  For this month, you have been invoiced for {variables.var1}{" "}
                  active card(s). You are currently attempting to make{" "}
                  {variables.var2} inactive card(s) active. You will be invoiced
                  for {variables.var3} card(s). Confirm to proceed.
                </div>
              </div>
            </div>
          }
          setShow={setConfirmation}
          show={showConfirmation}
          confirm={confirmCardStatusChange}
          submitting={submittingRequest}
        ></ConfirmModal>
      )}
      {showCancelConfirmation && (
        <ConfirmModal
          title={"Confirm cancel card"}
          body={
            <div className="font-medium">
              <div className="">
                <div className="mb-3">
                  This is a permanent action. Once a card is canceled, it cannot
                  be used again. Confirm to proceed.
                </div>
              </div>
            </div>
          }
          setShow={setCancelConfirmation}
          show={showCancelConfirmation}
          confirm={cardCancelhandleSubmit}
          submitting={cardCancelIsSubmitting}
        ></ConfirmModal>
      )}
    </div>
  );
};
