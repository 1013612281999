/** @format */

import {
  BanknotesIcon,
  BellIcon,
  BriefcaseIcon,
  CalculatorIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  CogIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentChartBarIcon,
  DocumentTextIcon,
  EllipsisHorizontalCircleIcon,
  GiftIcon,
  GlobeAltIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  TableCellsIcon,
  TruckIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { ENUM_CHILD_USER_TYPE, ENUM_CUSTOMER_TYPE } from "@prisma/client";

export const fuelcardRoutes = [
  {
    route: "/404",
    authenticated: false,
    dontShow: true,
    isMobileResponsive: true,
  },
  {
    route: "/help",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/account/email-verify",
    authenticated: false,
    isMobileResponsive: true,
  },
  {
    route: "/parent-company-user",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/account-settings",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/cards",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/card/[cardId]",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/virtual-cards",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/card-order-form",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/policies",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/alerts",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/account-tags",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/account-tags/new",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/account-tags/[tagId]",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/account-documents",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/policies/id/[policyId]",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/policies/new",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/dashboard",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/drivers",
    authenticated: true,
    isMobileResponsive: true,
  },

  {
    route: "/drivers/new",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/drivers/[driverId]",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/vehicles",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/vehicles/new",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/vehicles/[vehicleId]",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/fuel-station-finder",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/eld-systems",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/eld-systems/[orgName]",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/reports",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/reports-advanced",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/no-access",
    authenticated: false,
    isMobileResponsive: true,
  },
  {
    route: "/onboard",
    authenticated: false,
    isMobileResponsive: true,
  },
  {
    route: "/payments",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/payments/auto-reload",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/payments/repayment-plan",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/payments-history",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/multiple-users",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/multiple-users/[userId]",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/multiple-users/new",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/statements",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/accounting",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/referrals",
    authenticated: true,
    isMobileResponsive: false,
  },
  {
    route: "/transactions",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/transactions/[transactionId]",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/transactions/pending/[authorizationId]",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/transactions/flagged",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/transactions/declined",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/transactions/receipt-require",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/transactions/imported",
    authenticated: true,
    isMobileResponsive: true,
  },
  {
    route: "/",
    authenticated: false,
    dontShow: true,
    isMobileResponsive: true,
  },
];

const ALL_CUSTOMER_TYPES = [
  ENUM_CUSTOMER_TYPE.CREDIT,
  ENUM_CUSTOMER_TYPE.CREDIT_PLUS,
  ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE,
  ENUM_CUSTOMER_TYPE.PREPAID,
  ENUM_CUSTOMER_TYPE.PREPAID_PLUS,
  ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE,
  ENUM_CUSTOMER_TYPE.LARGE_FLEETS,
];

export const NavigationLinksFirst = [
  {
    name: "Parent Company User",
    href: "/parent-company-user",
    hrefAlt: undefined,
    icon: HomeIcon,
    current: 1,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: false,
    showForParentCompanyUser: true,
  },
  {
    name: "Dashboard",
    href: "/dashboard",
    hrefAlt: undefined,
    icon: HomeIcon,
    current: 2,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Transactions",
    href: "/transactions",
    hrefAlt: "/transactions/[transactionId]",
    icon: CurrencyDollarIcon,
    current: 3,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Account Tags",
    href: "/account-tags",
    hrefAlt: undefined,
    icon: BriefcaseIcon,
    current: 4,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: true,
    showForParentCompanyUser: false,
  },
];
export const NavigationLinksSecond = [
  {
    name: "Spend Policies",
    href: "/policies",
    hrefAlt: undefined,
    icon: EllipsisHorizontalCircleIcon,
    current: 5,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Cards",
    href: "/cards",
    hrefAlt: undefined,
    icon: CreditCardIcon,
    current: 6,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Virtual Cards",
    href: "/virtual-cards",
    hrefAlt: "virtual-cards/[cardId]",
    icon: CreditCardIcon,
    current: 7,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Vehicles",
    href: "/vehicles",
    hrefAlt: undefined,
    icon: TruckIcon,
    current: 8,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Drivers",
    href: "/drivers",
    hrefAlt: undefined,
    icon: UsersIcon,
    current: 9,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
];

export const NavigationLinksThird = [
  {
    name: "Statement/Invoice",
    href: "/statements",
    hrefAlt: undefined,
    icon: DocumentChartBarIcon,
    current: 10,
    canAccess: [
      ENUM_CUSTOMER_TYPE.CREDIT,
      ENUM_CUSTOMER_TYPE.CREDIT_PLUS,
      ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE,
    ],
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Payments History",
    href: "/payments-history",
    hrefAlt: undefined,
    icon: TableCellsIcon,
    current: 11,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Payment Methods",
    href: "/payments",
    hrefAlt: undefined,
    icon: BanknotesIcon,
    current: 12,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Accounting",
    href: "/accounting",
    hrefAlt: undefined,
    icon: CalculatorIcon,
    current: 13,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: true,
    showForParentCompanyUser: false,
  },
];
export const NavigationLinksFourth = [
  {
    name: "Notifications",
    href: "/alerts",
    hrefAlt: undefined,
    icon: BellIcon,
    current: 14,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: true,
    showForParentCompanyUser: false,
  },
];

export const NavigationLinksFifth = [
  {
    name: "Connect",
    href: "/eld-systems",
    hrefAlt: undefined,
    icon: GlobeAltIcon,
    current: 15,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: true,
    showForParentCompanyUser: false,
  },
  {
    name: "Fuel Station Finder",
    href: "/fuel-station-finder",
    hrefAlt: undefined,
    icon: MagnifyingGlassCircleIcon,
    current: 16,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: true,
    showForParentCompanyUser: false,
  },
];

export const NavigationLinksSixth = [
  {
    name: "Fleet Analysis",
    href: "/reports",
    hrefAlt: undefined,
    icon: ChartPieIcon,
    current: 17,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: true,
    showForParentCompanyUser: false,
  },
  {
    name: "Advanced Reporting",
    href: "/reports-advanced",
    hrefAlt: undefined,
    icon: ChartBarSquareIcon,
    current: 18,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: true,
    showForParentCompanyUser: false,
  },
  {
    name: "Account Documents",
    href: "/account-documents",
    hrefAlt: undefined,
    icon: DocumentTextIcon,
    current: 19,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: true,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
];

export const NavigationLinksSeventh = [
  {
    name: "Account Settings",
    href: "/account-settings",
    hrefAlt: undefined,
    icon: CogIcon,
    current: 20,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
  {
    name: "Users",
    href: "/multiple-users",
    hrefAlt: undefined,
    icon: UsersIcon,
    current: 21,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: true,
    showForParentCompanyUser: false,
  },
  {
    name: "Refer and Earn",
    href: "/referrals",
    hrefAlt: undefined,
    icon: GiftIcon,
    current: 22,
    canAccess: ALL_CUSTOMER_TYPES,
    canAccessRole: [
      ENUM_CHILD_USER_TYPE.OWNER,
      ENUM_CHILD_USER_TYPE.BOOKKEEPER,
      ENUM_CHILD_USER_TYPE.CARD_ADMIN,
      ENUM_CHILD_USER_TYPE.CARD_MANAGER,
      ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
    ],
    hideForDefaultUser: false,
    premiumIndicator: false,
    showForParentCompanyUser: false,
  },
];
