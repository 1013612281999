/** @format */

import {
  ArrowRightCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Bars3Icon,
  ChevronDownIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import { useScroll } from "@roadflex/react-hooks-www";
import { UserAuthType } from "@roadflex/types";
import {
  checkIfUserIsInImpersonatorMode,
  deleteCookie,
  getCookie,
} from "@roadflex/web-lib";
import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ButtonLink } from "../../../buttons/button-link/button-link";
import { Button } from "../../../buttons/button/button";
import { ButtonSize, ButtonVariant } from "../../../buttons/constants";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_WWW_URL, NEXT_PUBLIC_CDN_URL, NEXT_PUBLIC_APP_URL } =
  publicRuntimeConfig;
/* eslint-disable-next-line */
export interface HeaderProps {
  readCurrentUserAuth?: UserAuthType | undefined;
}
export function Header(props: HeaderProps) {
  const [isImpersonatorMode, setImpersonatorMode] = useState(false);
  const whyRoadFlexLinks = [
    { href: `${NEXT_PUBLIC_WWW_URL}/competition`, label: "Vs. Competition" },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/customer-success`,
      label: "Customer Success",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/customer-stories`,
      label: "Customer Stories",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/savings`, label: "Savings Calculator" },
  ];
  const productLinks = [
    {
      href: `${NEXT_PUBLIC_WWW_URL}/fuel-risk-management`,
      label: "Fuel Risk Management",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/security-features`,
      label: "Security Features",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/control-and-reporting`,
      label: "Controls & Reporting",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/integrations`, label: "Integrations" },
    { href: `${NEXT_PUBLIC_WWW_URL}/fleetcard`, label: "Expense Card" },
    { href: `${NEXT_PUBLIC_WWW_URL}/prefunded`, label: "Prefunded Account" },
    // { href: `${NEXT_PUBLIC_WWW_URL}/sustainability`, label: "Sustainability" },
  ];
  const partnerLinks = [
    {
      href: `${NEXT_PUBLIC_WWW_URL}/partnership`,
      label: "Become a partner",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/ecosystem`, label: "Ecosystem" },
    // { href: `${NEXT_PUBLIC_WWW_URL}/broker`, label: "Brokers" },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/affiliate-program`,
      label: "Affiliate Program",
    },
  ];
  const companyLinks = [
    { href: `${NEXT_PUBLIC_WWW_URL}/blog`, label: "Blog" },
    { href: `${NEXT_PUBLIC_WWW_URL}/in-the-news`, label: "In The News" },
    { href: `${NEXT_PUBLIC_WWW_URL}/career`, label: "Careers" },
    { href: `${NEXT_PUBLIC_WWW_URL}/veteran`, label: "Veterans" },
  ];

  const industryLinks = [
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/public-sector`,
      label: "Public Sector",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/construction`,
      label: "Construction",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/final-mile-delivery`,
      label: "Final Mile Delivery",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/industry/hvac`, label: "HVAC" },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/landscaping`,
      label: "Landscaping",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/passenger-transport`,
      label: "Passenger Transport",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/pest-control`,
      label: "Pest Control",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/industry/trucking`, label: "Trucking" },
    { href: `${NEXT_PUBLIC_WWW_URL}/industry/utilities`, label: "Utilities" },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/waste-management`,
      label: "Waste Management",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/plumbing`,
      label: "Plumbing",
    },
  ];

  const { readCurrentUserAuth } = props;
  const isScrolled = useScroll(0);
  const router = useRouter();

  const partnersLink: boolean =
    router.pathname === "/partners/advantageone" ||
    router.pathname === "/partners/fleetup" ||
    router.pathname === "/partners/promiles" ||
    router.pathname === "/partners/techsbook" ||
    router.pathname === "/partners/nalp" ||
    router.pathname === "/partners/npma" ||
    router.pathname === "/partners/linxup" ||
    router.pathname === "/partners/phcc" ||
    router.pathname === "/partners/geotabconnect";
  const showLinks =
    router.pathname !== "/deposit" &&
    router.pathname !== "/no-access" &&
    router.pathname !== "/onboard" &&
    !partnersLink &&
    router.pathname !== "/signin" &&
    router.pathname !== "/signup" &&
    router.pathname !== "/contact-us";
  const showSignUp = router.pathname === "/signin" || partnersLink;
  const showSignOut =
    router.pathname === "/phone-verification" ||
    router.pathname === "/details-update";
  const handleSignOut = () => {
    deleteCookie("token");
    router.push({
      pathname: `${NEXT_PUBLIC_WWW_URL}/signin`,
    });
  };
  const scrollToSignUp = () => {
    if (partnersLink) {
      const partnerName = router.pathname.split("/", 3)[2];
      router.push({
        pathname: `${NEXT_PUBLIC_WWW_URL}/signup`,
        query: { partner: partnerName },
      });
    } else {
      router.push({
        pathname: `${NEXT_PUBLIC_WWW_URL}/signup`,
        query: "",
      });
    }
  };

  const isIframe = router.pathname.includes(`roadflex-geotab-iframe`);

  useEffect(() => {
    setImpersonatorMode(checkIfUserIsInImpersonatorMode());
    const handleTabClose = () => {
      if (getCookie("impersonatorToken")) {
        deleteCookie("impersonatorToken");
      }
    };

    const onBeforeUnloadFunc = (event: Event) => {
      if (getCookie("impersonatorToken")) {
        const e = event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = false; // Legacy method for cross browser support
        }
        return ""; // Legacy method for cross browser support
      }
      return true;
    };

    window.addEventListener("beforeunload", onBeforeUnloadFunc);
    window.addEventListener("unload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnloadFunc);
      window.removeEventListener("unload", handleTabClose);
    };
  }, [readCurrentUserAuth]);

  return (
    <header
      id="header"
      className={classNames(
        !isScrolled ? "bg-white opacity-95" : "bg-white opacity-95",
        "flex flex-col md:flex-row h-16 fixed w-full border-b border-gray-300 border-dotted z-50",
      )}
    >
      <div className="container 3xl:max-w-[1750px!important] flex flex-grow-1 justify-between items-center py-2 test-sm">
        {router.pathname !== "/" &&
          router.pathname !== "/contact-us" &&
          router.pathname !== "/signup" && (
            <div className="flex flex-row h-full py-2">
              {router.pathname === "/partners/advantageone" && (
                <div className="flex flex-row">
                  <Link href="https://www.advantageone.tech/" prefetch={false}>
                    <a
                      href="https://www.advantageone.tech/"
                      className="relative w-20 sm:w-48 md:w-52 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/advantageone/advantage-one-logo.png`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === "/partners/fleetup" && (
                <div className="flex flex-row">
                  <Link href="https://fleetup.com/" prefetch={false}>
                    <a
                      href="https://fleetup.com/"
                      className="relative w-14 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/fleetup/fleetup-logo.jpeg`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === "/partners/linxup" && (
                <div className="flex flex-row">
                  <Link href="https://www.linxup.com/" prefetch={false}>
                    <a
                      href="https://www.linxup.com/"
                      className="relative w-12 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/linxup/linxup-logo.png`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === "/partners/techsbook" && (
                <div className="flex flex-row">
                  <Link href="https://techsbook.com/" prefetch={false}>
                    <a
                      href="https://techsbook.com/"
                      className="relative w-14 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/techsbook/techsbook-logo.png`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === "/partners/npma" && (
                <div className="flex flex-row">
                  <Link href="https://www.npmapestworld.org/" prefetch={false}>
                    <a
                      href="https://www.npmapestworld.org/"
                      className="relative w-14 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/npma/npma-logo.png`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === "/partners/nalp" && (
                <div className="flex flex-row">
                  <Link
                    href="https://www.landscapeprofessionals.org/"
                    prefetch={false}
                  >
                    <a
                      href="https://www.landscapeprofessionals.org/"
                      className="relative w-14 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/nalp/nalp-logo.png`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === "/partners/phcc" && (
                <div className="flex flex-row">
                  <Link href="https://www.phccweb.org/" prefetch={false}>
                    <a
                      href="https://www.phccweb.org/"
                      className="relative w-8 md:w-20 min-h-[20px] max-h-[36px] md:h-full self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/phcc/company-logo.png`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === "/partners/promiles" && (
                <div className="flex flex-row">
                  <Link href="https://www.promiles.com/" prefetch={false}>
                    <a
                      href="https://www.promiles.com/"
                      className="relative w-14 md:w-28 min-h-[10px] max-h-[24px] md:h-full self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/promiles/company-logo.png`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === "/partners/geotabconnect" && (
                <div className="flex flex-row">
                  <Link href="https://www.geotab.com/" prefetch={false}>
                    <a
                      href="https://www.geotab.com/"
                      className="relative w-14 md:w-32 min-h-[12px] max-h-[22px] md:h-full self-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/geotab/geotab-logo.png`}
                        alt="RoadFlex partner logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                  <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                    <Image
                      className="p-0 m-0"
                      src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                      alt="RoadFlex partnership"
                      layout="fill"
                    />
                  </div>
                  <Link href="/">
                    <a
                      href="/"
                      className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                    >
                      <Image
                        className="p-0 m-0 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                        alt="RoadFlex logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
              )}

              {!partnersLink && !isIframe && (
                <Link href="/">
                  <a
                    href="/"
                    className="relative w-40 sm:w-48 md:w-60 h-[30px] max-h-[36px] md:h-full self-center"
                  >
                    {!isScrolled ? (
                      <Image
                        className="p-0 m-0 h-9 md:max-h-2 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/common/roadflex-logos/svg/roadflex-logo-black.svg`}
                        alt="RoadFlex logo"
                        layout="fill"
                        priority={true}
                      />
                    ) : (
                      <Image
                        className="p-0 m-0 h-9 hover:cursor-pointer"
                        src={`${NEXT_PUBLIC_CDN_URL}/common/roadflex-logos/svg/roadflex-logo-black.svg`}
                        alt="RoadFlex logo"
                        layout="fill"
                        priority={true}
                      />
                    )}
                  </a>
                </Link>
              )}
              {isIframe && (
                <div className="relative w-40 sm:w-48 md:w-60 h-[30px] max-h-[36px] md:h-full self-center">
                  <Image
                    className="p-0 m-0 h-9 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/common/roadflex-logos/svg/roadflex-logo-black.svg`}
                    alt="RoadFlex logo"
                    layout="fill"
                    priority={true}
                  />
                </div>
              )}
            </div>
          )}

        {(router.pathname === "/" ||
          router.pathname === "/contact-us" ||
          router.pathname === "/signup") && (
          <div className="flex flex-row h-full max-w-sm 2xl:max-w-lg">
            <Link href="/">
              <a href="/" className="">
                <video className="h-12 p-0 m-0" autoPlay muted playsInline>
                  <source
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/video/moving-truck-header.mp4`}
                    type="video/mp4"
                  />
                </video>
              </a>
            </Link>
          </div>
        )}
        <div className="flex flex-row items-center my-1 ml-4 text-sm">
          {isImpersonatorMode ? (
            <div className="relative px-2 py-1 my-3 mr-4 text-sm font-medium bg-red-200 rounded-md sm:mr-6 sm:my-2">
              <div className="flex items-center gap-1">
                <ExclamationCircleIcon className="w-4 h-4 text-red-700 sm:w-5 sm:h-5" />
                <span className="text-red-600">Impersonator Mode</span>
              </div>
            </div>
          ) : null}

          {showLinks && !isIframe ? (
            <>
              <div className="relative hidden text-left lg:inline-block">
                <div className="group justify-center w-full gap-x-1.5 px-2.5 py-5 font-semibold text-black items-center">
                  <div className="relative text-black cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-black before:origin-center before:h-[1px] before:w-0 group-hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-black after:origin-center after:h-[1px] after:w-0 group-hover:after:w-[50%] after:bottom-0 after:right-[50%] items-center">
                    Why RoadFlex
                    <ChevronDownIcon
                      className="inline-flex w-3 h-3 mx-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="absolute right-0 z-10 hidden w-52 mt-[20px] origin-top-right bg-white group-hover:block shadow-b-md rounded-b-md opacity-95">
                    {whyRoadFlexLinks.map((link, index) => (
                      <div key={link.href} className="">
                        {index !== 0 && (
                          <div
                            key={index}
                            className="border border-b-0 border-l-0 border-r-0 border-gray-300 border-dotted"
                          ></div>
                        )}
                        <div>
                          <a
                            href={link.href}
                            className={classNames(
                              "block px-4 py-2",
                              "font-semibold flex items-center justify-between cursor-pointer hover:animate-pulse",
                            )}
                          >
                            {link.label}{" "}
                            <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="relative hidden text-left lg:inline-block">
                <div className="group justify-center w-full gap-x-1.5 px-2.5 py-5 font-semibold text-black items-center">
                  <div className="relative text-black cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-black before:origin-center before:h-[1px] before:w-0 group-hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-black after:origin-center after:h-[1px] after:w-0 group-hover:after:w-[50%] after:bottom-0 after:right-[50%] items-center">
                    Product
                    <ChevronDownIcon
                      className="inline-flex w-3 h-3 mx-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="absolute right-0 z-10 hidden w-56 mt-[20px] origin-top-right bg-white group-hover:block shadow-b-md rounded-b-md opacity-95">
                    {productLinks.map((link, index) => (
                      <div key={link.href} className="">
                        {index !== 0 && (
                          <div
                            key={index}
                            className="border border-b-0 border-l-0 border-r-0 border-gray-300 border-dotted"
                          ></div>
                        )}
                        <div>
                          <a
                            href={link.href}
                            className={classNames(
                              "block px-4 py-2",
                              "font-semibold flex items-center justify-between cursor-pointer hover:animate-pulse",
                            )}
                          >
                            {link.label}{" "}
                            <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="relative hidden text-left lg:inline-block">
                <div className="group justify-center w-full gap-x-1.5 px-2.5 py-5 font-semibold text-black items-center">
                  <div className="relative text-black cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-black before:origin-center before:h-[1px] before:w-0 group-hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-black after:origin-center after:h-[1px] after:w-0 group-hover:after:w-[50%] after:bottom-0 after:right-[50%] items-center">
                    Industries
                    <ChevronDownIcon
                      className="inline-flex w-3 h-3 mx-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="absolute right-0 z-10 hidden w-52 mt-[20px] origin-top-right bg-white group-hover:block shadow-b-md rounded-b-md opacity-95">
                    {industryLinks.map((link, index) => (
                      <div key={link.href} className="">
                        {index !== 0 && (
                          <div
                            key={index}
                            className="border border-b-0 border-l-0 border-r-0 border-gray-300 border-dotted"
                          ></div>
                        )}
                        <div>
                          <a
                            href={link.href}
                            className={classNames(
                              "block px-4 py-2",
                              "font-semibold flex items-center justify-between cursor-pointer hover:animate-pulse",
                            )}
                          >
                            {link.label}{" "}
                            <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="relative hidden text-left lg:inline-block">
                <div className="group justify-center w-full gap-x-1.5 px-2.5 py-5 font-semibold text-black items-center">
                  <div className="relative text-black cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-black before:origin-center before:h-[1px] before:w-0 group-hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-black after:origin-center after:h-[1px] after:w-0 group-hover:after:w-[50%] after:bottom-0 after:right-[50%] items-center">
                    Partners
                    <ChevronDownIcon
                      className="inline-flex w-3 h-3 mx-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="absolute right-0 z-10 hidden w-52 mt-[20px] origin-top-right bg-white group-hover:block shadow-b-md rounded-b-md opacity-95">
                    {partnerLinks.map((link, index) => (
                      <div key={link.href} className="">
                        {index !== 0 && (
                          <div
                            key={index}
                            className="border border-b-0 border-l-0 border-r-0 border-gray-300 border-dotted"
                          ></div>
                        )}
                        <div>
                          <a
                            href={link.href}
                            className={classNames(
                              "block px-4 py-2",
                              "font-semibold flex items-center justify-between cursor-pointer hover:animate-pulse",
                            )}
                          >
                            {link.label}{" "}
                            <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="relative hidden text-left lg:inline-block">
                <div className="group justify-center w-full gap-x-1.5 px-2.5 py-5 font-semibold text-black items-center">
                  <div className="relative text-black cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-black before:origin-center before:h-[1px] before:w-0 group-hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-black after:origin-center after:h-[1px] after:w-0 group-hover:after:w-[50%] after:bottom-0 after:right-[50%] items-center">
                    <Link href={`${NEXT_PUBLIC_WWW_URL}/faqs`}>
                      <div
                        className={classNames(
                          "font-semibold flex items-center cursor-pointer sm:px-2",
                        )}
                      >
                        FAQ
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="relative hidden text-left lg:inline-block">
                <div className="group justify-center w-full gap-x-1.5 px-2.5 py-5 font-semibold text-black items-center">
                  <div className="relative text-black cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-black before:origin-center before:h-[1px] before:w-0 group-hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-black after:origin-center after:h-[1px] after:w-0 group-hover:after:w-[50%] after:bottom-0 after:right-[50%] items-center">
                    Company
                    <ChevronDownIcon
                      className="inline-flex w-3 h-3 mx-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="absolute right-0 z-10 hidden w-52 mt-[20px] origin-top-right bg-white group-hover:block shadow-b-md rounded-b-md opacity-95">
                    {" "}
                    {companyLinks.map((link, index) => (
                      <div key={link.href} className="">
                        {index !== 0 && (
                          <div
                            key={index}
                            className="border border-b-0 border-l-0 border-r-0 border-gray-300 border-dotted"
                          ></div>
                        )}
                        <div>
                          <a
                            href={link.href}
                            className={classNames(
                              "block px-4 py-2",
                              "font-semibold flex items-center justify-between cursor-pointer hover:animate-pulse",
                            )}
                          >
                            {link.label}{" "}
                            <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {showSignUp ? (
            <Button
              variant={ButtonVariant.Orange}
              size={ButtonSize.Normal}
              onClick={scrollToSignUp}
              className="ml-4 md:ml-10 whitespace-nowrap"
            >
              {!partnersLink && <>Sign Up</>}
              {partnersLink && <>Apply Now</>}
            </Button>
          ) : showSignOut || readCurrentUserAuth ? (
            <div className="flex flex-row items-center gap-3">
              <div className="flex-row items-center hidden gap-3 sm:flex">
                <div>Need Help?</div>
                <div className="flex flex-row items-center">
                  <PhoneIcon className="w-5 h-5 mr-2"></PhoneIcon>
                  <Link href="tel:8778707440">
                    <div className="cursor-pointer whitespace-nowrap">
                      (877) 870-7440
                    </div>
                  </Link>
                </div>
              </div>
              <Button
                onClick={handleSignOut}
                variant={ButtonVariant.Orange}
                size={ButtonSize.Normal}
                className="ml-4 font-medium xl:ml-10 whitespace-nowrap"
              >
                Sign Out
              </Button>
            </div>
          ) : !isIframe ? (
            <ButtonLink
              variant={ButtonVariant.Orange}
              size={ButtonSize.Normal}
              href={`${NEXT_PUBLIC_WWW_URL}/signin`}
              className="ml-4 font-medium xl:ml-10 whitespace-nowrap"
            >
              Log In
            </ButtonLink>
          ) : null}
          {showLinks && !isIframe ? (
            <div className="relative inline-block text-sm text-left group lg:hidden">
              <div className="inline-flex justify-center gap-x-1.5 px-2.5 py-2 font-semibold text-black">
                <Bars3Icon
                  className="w-5 h-5 ml-2 -mr-1 text-gray-800"
                  aria-hidden="true"
                />
              </div>
              <div className="hidden group-hover:block absolute right-0 z-10 w-52 mt-2 max-h-[80vh] overflow-y-auto origin-top-right bg-white rounded-md shadow-md py-2">
                <div className="border border-b-0 border-l-0 border-r-0 border-gray-3">
                  {whyRoadFlexLinks.map((link, index) => (
                    <div key={link.href} className="py-0">
                      <div>
                        <a
                          href={link.href}
                          className={classNames(
                            "block px-4 py-1.5",
                            "font-semibold flex items-center justify-between cursor-pointer",
                          )}
                        >
                          {link.label}{" "}
                          <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="border border-b-0 border-l-0 border-r-0 border-gray-3">
                  {productLinks.map((link, index) => (
                    <div key={link.href} className="py-0">
                      <div>
                        <a
                          href={link.href}
                          className={classNames(
                            "block px-4 py-1.5",
                            "font-semibold flex items-center justify-between cursor-pointer",
                          )}
                        >
                          {link.label}{" "}
                          <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="border border-b-0 border-l-0 border-r-0 border-gray-3">
                  {industryLinks.map((link, index) => (
                    <div key={link.href} className="py-0">
                      <div>
                        <a
                          href={link.href}
                          className={classNames(
                            "block px-4 py-1.5",
                            "font-semibold flex items-center justify-between cursor-pointer",
                          )}
                        >
                          {link.label}{" "}
                          <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="border border-b-0 border-l-0 border-r-0 border-gray-3">
                  {partnerLinks.map((link, index) => (
                    <div key={link.href} className="py-0">
                      <div>
                        <a
                          href={link.href}
                          className={classNames(
                            "block px-4 py-1.5",
                            "font-semibold flex items-center justify-between cursor-pointer",
                          )}
                        >
                          {link.label}{" "}
                          <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="border border-b-0 border-l-0 border-r-0 border-gray-3">
                  {companyLinks.map((link, index) => (
                    <div key={link.href} className="py-0">
                      <div>
                        <a
                          href={link.href}
                          className={classNames(
                            "block px-4 py-1.5",
                            "font-semibold flex items-center justify-between cursor-pointer",
                          )}
                        >
                          {link.label}{" "}
                          <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
}

export default Header;
