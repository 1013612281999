/** @format */

import {
  useReadAuthorizations,
  useReadAuthorizationsExport,
  useReadFilterOptions,
} from "@roadflex/react-hooks";
import {
  AuthorizationType,
  CardsFilterOptions,
  DriversFilterOptions,
  ParameterFilterType,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Toast } from "../../../toast-message/toast";
import TransactionsDeclinedUI from "./transactions-declined.ui";

type TransactionsDeclinedProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  cardTags: ParameterFilterType[];
};

type FilterProps2 = "driver" | "vehicle" | "vehicleVIN" | "lastFour";

type FilterProps =
  | "date"
  | "merchantName"
  | "merchantState"
  | "transactionAmount"
  | "status";

type FilterConfigType = {
  [Key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} & {
  [Key in FilterProps2]: {
    value: null | string;
    matchMode: FilterMatchMode;
  };
};

const initFilterConfig: FilterConfigType = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  merchantName: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  merchantState: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  status: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  lastFour: { value: null, matchMode: FilterMatchMode.IN },
  driver: { value: null, matchMode: FilterMatchMode.IN },
  vehicle: { value: null, matchMode: FilterMatchMode.IN },
  vehicleVIN: { value: null, matchMode: FilterMatchMode.IN },
};

const authorizationInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "date",
  sortOrder: -1,
  multiSortMeta: null,
};

export const TransactionsDeclined = ({
  readCurrentUserAuth,
  cardTags,
  loading,
}: TransactionsDeclinedProps) => {
  const [authorizationLazyParams, setAuthorizationLazyParams] =
    useState<DataTablePFSEvent>({
      ...authorizationInitialLazyParams,
    });

  const [authorizationLazyParamsExport, setAuthorizationLazyParamsExport] =
    useState<DataTablePFSEvent>({
      ...authorizationInitialLazyParams,
    });
  const {
    authorizationDataExport,
    authorizationRefetchExport,
    authorizationLoadingExport,
  } = useReadAuthorizationsExport(
    authorizationLazyParamsExport,
    false,
    "",
    cardTags.length > 0 ? cardTags.map((x) => x.tag) : undefined,
  );

  const { authorizationData, authorizationRefetch, authorizationLoading } =
    useReadAuthorizations(
      authorizationLazyParams,
      false,
      "",
      cardTags.length > 0 ? cardTags.map((x) => x.tag) : undefined,
    );

  const { filterOptions } = useReadFilterOptions();

  const [declinedTransactionData, setDeclinedTransactionData] = useState<{
    authorizations: AuthorizationType[];
    totalCount: number;
  }>({
    authorizations: [],
    totalCount: 0,
  });

  const [declinedTransactionDataExport, setDeclinedTransactionDataExport] =
    useState<{
      authorizations: AuthorizationType[];
      totalCount: number;
    }>({
      authorizations: [],
      totalCount: 0,
    });
  useEffect(() => {
    if (
      !authorizationLoading &&
      authorizationData &&
      authorizationData?.readAuthorizationList?.code !== "200"
    ) {
      Toast({
        type: "error",
        message: authorizationData?.readAuthorizationList?.message || "",
      });
    }
    if (
      authorizationData &&
      authorizationData.readAuthorizationList.code === "200"
    ) {
      setDeclinedTransactionData({
        totalCount: authorizationData.readAuthorizationList.totalCount,
        authorizations: authorizationData.readAuthorizationList.authorizations,
      });
    }
  }, [authorizationData, authorizationLoading]);

  useEffect(() => {
    if (
      !authorizationLoadingExport &&
      authorizationDataExport &&
      authorizationDataExport?.readAuthorizationList?.code !== "200"
    ) {
      Toast({
        type: "error",
        message: authorizationDataExport?.readAuthorizationList?.message || "",
      });
    }
    if (
      authorizationDataExport &&
      authorizationDataExport.readAuthorizationList.code === "200"
    ) {
      setDeclinedTransactionDataExport({
        totalCount: authorizationDataExport.readAuthorizationList.totalCount,
        authorizations:
          authorizationDataExport.readAuthorizationList.authorizations,
      });
    }
  }, [authorizationDataExport, authorizationLoadingExport]);

  useEffect(() => {
    authorizationRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationLazyParams]);

  useEffect(() => {
    authorizationRefetchExport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationLazyParamsExport]);

  const [transactionFilterOptions, setTransactionFilterOptions] = useState<{
    vehiclesFilterOptions: VehiclesFilterOptions[];
    cardsFilterOptions: CardsFilterOptions[];
    driversFilterOptions: DriversFilterOptions[];
  }>({
    vehiclesFilterOptions: [],
    cardsFilterOptions: [],
    driversFilterOptions: [],
  });

  useEffect(() => {
    if (filterOptions?.transactionFilterOptions) {
      setTransactionFilterOptions({
        cardsFilterOptions: filterOptions.transactionFilterOptions.cards.map(
          (value) => {
            return {
              lastFour: value.lastFour,
            };
          },
        ),
        vehiclesFilterOptions:
          filterOptions.transactionFilterOptions.vehicles.map((value) => {
            return {
              vehicleName: value.fleetName,
            };
          }),
        driversFilterOptions:
          filterOptions.transactionFilterOptions.drivers.map((value) => {
            return {
              driverName: value.name,
            };
          }),
      });
    }
  }, [filterOptions?.transactionFilterOptions]);

  if (loading) {
    return null;
  }

  return (
    <TransactionsDeclinedUI
      {...{
        setAuthorizationLazyParamsExport,
        declinedTransactionData,
        declinedTransactionDataExport,
        transactionFilterOptions,
        authorizationLazyParams,
        setAuthorizationLazyParams,
        authorizationLoading,
        authorizationLoadingExport,
        readCurrentUserAuth,
      }}
    />
  );
};
