/** @format */

import { useMutation, useQuery } from "@apollo/client";
import { ENUM_ELD_ORG_NAME } from "@prisma/client";
import {
  ELD_CONNECTED,
  FLEETIO_CONNECTED,
  IS_EJ_WARD_CONNECTED,
  REMOVE_EJ_WARD,
  REMOVE_ELD_CONNECTION,
  REMOVE_FLEETIO,
} from "@roadflex/graphql";
import { useGeoTabAuthenticate } from "@roadflex/react-hooks";
import { EldSystem, UserAuthType } from "@roadflex/types";
import getConfig from "next/config";
import { useEffect, useState } from "react";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import { ELDSystemsUI } from "./eld-systems.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

type ELDSystemsProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
};

export const ELDSystems = ({
  readCurrentUserAuth,
  loading,
}: ELDSystemsProps) => {
  const eldSystems: EldSystem[] = [
    {
      name: "GeoTab",
      code: ENUM_ELD_ORG_NAME.GEOTAB,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/geotab.svg`,
    },
    {
      name: "Samsara",
      code: ENUM_ELD_ORG_NAME.SAMSARA,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/samsara.svg`,
    },
    {
      name: "Azuga",
      code: ENUM_ELD_ORG_NAME.AZUGA,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/azuga.svg`,
    },
    {
      name: "UTH",
      code: ENUM_ELD_ORG_NAME.UTH,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/utech_blue.svg`,
    },
    {
      name: "FleetUp",
      code: ENUM_ELD_ORG_NAME.FLEETUP,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/fleetup-logo.svg`,
    },
    {
      name: "Verizon",
      code: ENUM_ELD_ORG_NAME.VERIZON,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/verizon.svg`,
    },
    {
      name: "NexTraq",
      code: ENUM_ELD_ORG_NAME.NEXTRAQ,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/nextraq.svg`,
    },
    {
      name: "Bouncie",
      code: ENUM_ELD_ORG_NAME.BOUNCIE,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/bouncie.svg`,
    },
    {
      name: "Fleetr",
      code: ENUM_ELD_ORG_NAME.FLEETR,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/fleetr.svg`,
    },
  ];

  const [openAddELDSystemModal, setOpenAddELDSystemModal] = useState(false);
  const [openAddFleetioModal, setOpenAddFleetioModal] = useState(false);
  const [openAddEJWardModal, setOpenAddEJWardModal] = useState(false);
  const [showConfirmDisconnectModal, setShowConfirmDisconnectModal] =
    useState(false);
  const [selectedEldSystem, setSelectedEldSystem] = useState<EldSystem>(
    eldSystems[0],
  );

  const { data: fleetioConnected } = useQuery<{
    isFleetioConnected: {
      code: number;
      message: string;
      connected: boolean;
      deleted: boolean;
    };
  }>(FLEETIO_CONNECTED, {
    fetchPolicy: "no-cache",
  });

  const { data: ejWardConnected } = useQuery<{
    isEjWardConnected: { code: string; message: true };
  }>(IS_EJ_WARD_CONNECTED, {
    fetchPolicy: "no-cache",
  });

  const { data: eldConnected } = useQuery<{
    isEldConnected: {
      isConnected: boolean;
      orgName: ENUM_ELD_ORG_NAME;
    };
  }>(ELD_CONNECTED, {
    fetchPolicy: "no-cache",
  });

  const [disconnectFleetioFn, { loading: disconnectFleetioLoading }] =
    useMutation<{
      removeFleetioConnection: {
        message: string;
      };
    }>(REMOVE_FLEETIO, {
      refetchQueries: [FLEETIO_CONNECTED],
      notifyOnNetworkStatusChange: true,
    });

  const [disconnectEJWardFn, { loading: disconnectEJWardLoading }] =
    useMutation<{
      removeEjWard: {
        code: string;
        message: string;
        type: string;
        success: string;
      };
    }>(REMOVE_EJ_WARD, {
      refetchQueries: [IS_EJ_WARD_CONNECTED],
      notifyOnNetworkStatusChange: true,
    });

  const [disconnectEldFn, { loading: disconnecEldLoading }] = useMutation<{
    EldRemove: {
      message: string;
    };
  }>(REMOVE_ELD_CONNECTION, {
    refetchQueries: [ELD_CONNECTED],
    notifyOnNetworkStatusChange: true,
  });

  const { resetForm: geoTabAuthResetForm, ...geoTabAuthFormikValues } =
    useGeoTabAuthenticate({
      initialValues: {
        userName: "",
        password: "",
        database: "",
        orgName: selectedEldSystem.code,
        token: "",
        clientId: "",
        acctId: "",
        apiKey: "",
        secret: "",
      },

      onSubmit(res, err) {
        if (err) {
          Toast({ type: "error", message: err.message });
          return;
        }
        // console.log(res, err);
        const sessionId = res?.eldAuthenticate?.sessionId || "";
        // console.log("sessionId => ", sessionId);
        if (sessionId) {
          setOpenAddELDSystemModal(false);
          Toast({
            type: "success",
            message: `${selectedEldSystem.name} account successfully authenticated.`,
          });
          geoTabAuthResetForm();
        } else {
          Toast({
            type: "error",
            message: "Authentication failed. Please try again",
          });
        }
      },
    });

  const disconnectFleetio = async () => {
    try {
      await disconnectFleetioFn();
      Toast({ type: "success", message: "Fleetio disconnected successfully" });
      setShowConfirmDisconnectModal(false);
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    }
  };

  const disconnectEJWard = async () => {
    try {
      const result = await disconnectEJWardFn();
      if (result?.data?.removeEjWard?.success) {
        Toast({ type: "success", message: result.data.removeEjWard.message });
      } else {
        Toast({
          type: "error",
          message: result?.data?.removeEjWard?.message || "",
        });
      }
      setShowConfirmDisconnectModal(false);
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    }
  };

  const disconnectEld = async (orgName: ENUM_ELD_ORG_NAME) => {
    try {
      await disconnectEldFn({
        variables: {
          data: { orgName },
        },
      });
      Toast({
        type: "success",
        message: `Telematics integration disconnected successfully`,
      });
      setShowConfirmDisconnectModal(false);
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    }
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const bouncieCode = queryParams.get("code");
    const bouncieState = queryParams.get("state");
    if (bouncieState === "BOUNCIE" && bouncieCode) {
      setSelectedEldSystem({
        name: "Bouncie",
        code: ENUM_ELD_ORG_NAME.BOUNCIE,
        imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/bouncie.svg`,
      });
      setOpenAddELDSystemModal(true);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  const removeEldLoading =
    disconnecEldLoading || disconnectEJWardLoading || disconnectFleetioLoading;

  return (
    <ELDSystemsUI
      {...{
        openAddELDSystemModal,
        setOpenAddELDSystemModal,
        eldSystems,
        selectedEldSystem,
        setSelectedEldSystem,
        geoTabAuthForm: {
          resetForm: geoTabAuthResetForm,
          ...geoTabAuthFormikValues,
        },
        setOpenAddFleetioModal,
        openAddFleetioModal,
        openAddEJWardModal,
        setOpenAddEJWardModal,
        isFleetioConnected:
          fleetioConnected?.isFleetioConnected.connected || false,
        isFleetioDeleted: fleetioConnected?.isFleetioConnected.deleted || false,
        isEJWardConnected:
          ejWardConnected?.isEjWardConnected?.code === "200" || false,
        disconnectFleetio,
        disconnectEJWard,
        disconnectEld,
        eldConnected: eldConnected?.isEldConnected || null,
        customerType: readCurrentUserAuth.fuelCard.customerType,
        loggedInId: readCurrentUserAuth.loggedInId,
        showConfirmDisconnectModal,
        setShowConfirmDisconnectModal,
        removeEldLoading,
      }}
    />
  );
};
