/** @format */
import { useLazyQuery } from "@apollo/client";
import {
  ADMIN_EXPORT_TRANSACTIONS_DATA,
  ADMIN_EXPORT_USERS_DATA,
} from "@roadflex/graphql";

export function useAdminExportUsersData() {
  const [
    loadAdminExportUsersDataFn,
    {
      data: adminExportUsersData,
      loading: adminExportUsersDataLoading,
      error: adminExportUsersError,
    },
  ] = useLazyQuery(ADMIN_EXPORT_USERS_DATA, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loadAdminExportUsersDataFn,
    adminExportUsersData,
    adminExportUsersDataLoading,
    adminExportUsersError,
  };
}

export function useAdminExportTransactions() {
  const [
    adminExportTransactionsFn,
    {
      data: adminExportTransactionsData,
      loading: adminExportTransactionsLoading,
      error: adminExportTransactionsError,
    },
  ] = useLazyQuery(ADMIN_EXPORT_TRANSACTIONS_DATA, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  return {
    adminExportTransactionsFn,
    adminExportTransactionsData,
    adminExportTransactionsLoading,
    adminExportTransactionsError,
  };
}
